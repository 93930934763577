import { newPatientSchema } from "../../components/FormComponents/AppointmentForms/Sections/NewPatient/newPatientSchema";
import { followUpPatientSchema } from "../../components/FormComponents/AppointmentForms/Sections/FollowUpPatient/followUpPatientSchema";
import {
	newBookingSchema,
	faceToFaceContactTypeRefinement,
} from "../../components/FormComponents/AppointmentForms/Sections/NewBooking/newBookingSchema";
import {
	purchaserMembershipNumberRefinement,
	purchaserSchema,
} from "../../components/FormComponents/AppointmentForms/Sections/Purchaser/purchaserSchema";
import { z } from "zod";

export const addAppointmentNew = z
	.object({
		...newPatientSchema,
		...newBookingSchema,
		...purchaserSchema,
		notes: z.string().optional(),
	})
	.refine(faceToFaceContactTypeRefinement.condition, faceToFaceContactTypeRefinement.args)
	.refine(purchaserMembershipNumberRefinement.condition, purchaserMembershipNumberRefinement.args);

export const addAppointmentFollowUp = z
	.object({
		...followUpPatientSchema,
		...newBookingSchema,
		...purchaserSchema,
		notes: z.string().optional(),
	})
	.refine(faceToFaceContactTypeRefinement.condition, faceToFaceContactTypeRefinement.args)
	.refine(purchaserMembershipNumberRefinement.condition, purchaserMembershipNumberRefinement.args);

export const addAppointmentNewDefaultValues = (date = new Date(), time = "08:00", duration = null) => ({
	patient: null,
	referralDate: new Date(),
	referrer: null,
	caseHolder: null,
	diagnosis: null,
	date: date,
	time: time,
	duration: duration,
	isFaceToFace: true,
	contactType: null,
	purchaser: null,
	authCode: "",
	membershipNumber: "",
	notes: "",
});

export const addAppointmentFollowUpDefaultValues = (date = new Date(), time = "08:00", duration = null) => ({
	patient: null,
	episodeOfCare: null,
	date: date,
	time: time,
	duration: duration,
	isFaceToFace: true,
	contactType: null,
	purchaser: null,
	authCode: "",
	membershipNumber: "",
	notes: "",
});
