import { Dropdown, Lookup } from "@streets-heaver/shui2";
import { Switch, Timebox } from "@streets-heaver/shui2/inputs";
import { Controller, useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useContactTypesLookup } from "../../../../../api/hooks";
import { durationOptions } from "../NewBooking/durationOptions";
import dayjs from "dayjs";

export const EditBooking = () => {
	const form = useFormContext();

	const arrivedName = "arrived";

	const durationName = "duration";
	const isFaceToFaceName = "isFaceToFace";
	const contactTypeName = "contactType";

	const contactTypeQuery = useContactTypesLookup();

	return (
		<FormGroup>
			<div style={{ width: 160 }}>
				<FormField
					label="Arrived"
					action={{ actionOnClick: () => form.setValue(arrivedName, dayjs().format("HH:mm")), actionText: "Now" }}
				>
					<Controller
						name={arrivedName}
						control={form.control}
						render={({ field: { onChange, value, ref } }) => (
							<Timebox
								type="filledDarker"
								size="large"
								dropdownStep={5}
								inputStep={5}
								value={value}
								onChange={onChange}
								ref={ref}
							/>
						)}
					/>
				</FormField>
			</div>
			<div style={{ width: 200 }}>
				<FormField label="Duration" name={durationName} isMandatory>
					<Controller
						name={durationName}
						render={({ field: { value, onChange } }) => (
							<Dropdown
								placeholder={{ label: "Select a duration" }}
								value={value}
								onChange={onChange}
								type={"filledDarker"}
								size="large"
								options={durationOptions}
							/>
						)}
					/>
				</FormField>
			</div>
			<FormField label="Is face to face?" isMandatory name={isFaceToFaceName}>
				<Controller
					control={form.control}
					name={isFaceToFaceName}
					render={({ field: { onChange, value } }) => (
						<Switch checked={value} onChange={onChange} label={value ? "Yes" : "No"} />
					)}
				/>
			</FormField>
			{!form.watch(isFaceToFaceName) && (
				<div style={{ width: 340 }}>
					<FormField label="Contact type" name={contactTypeName} isMandatory>
						<Controller
							name={contactTypeName}
							control={form.control}
							render={({ field: { value, onChange } }) => (
								<Lookup
									dropdownMaxHeight={"calc(80vh - 190px)"}
									selected={value}
									setSelected={onChange}
									searchQueryData={contactTypeQuery}
								/>
							)}
						/>
					</FormField>
				</div>
			)}
		</FormGroup>
	);
};
