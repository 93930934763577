import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { widgetRefetchInterval } from "../../../globals/constants";

export const useOrderStatusWidget = (patientId) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	const query = useQuery({
		queryKey: ["orderStatusWidget", currentClinician, patientId],
		queryFn: async () => {
			const data = await makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/patient/${patientId}/widget/orderstatus`,
			);
			if (data?.request?.response) throw new Error("Getting order status for patient Failed");
			return data;
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId,
	});

	return query;
};
