import { Toolbar } from "@streetsheaver/compucore";
import { ErrorDisplay } from "@streets-heaver/shui2";
import { Outlet, useLocation } from "react-router";
import { ActionPaths } from "./actionPaths";
import { newButton, editButton, printButton } from "./toolbarButtons";
import { isProduction } from "../../globals/constants";
import { usePageError } from "../../utils/hooks/usePageError";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";

export const Page = () => {
	const { currentClinician } = useCurrentClinicians();
	const error = usePageError();
	const location = useLocation();
	const pathname = location.pathname;
	const [_, datasource, ...pagepath] = pathname.split("/");
	const finalUrl = pagepath.join("/");
	const toolbarButtons = [
		newButton(!currentClinician, {
			pathname: `${finalUrl ? `${finalUrl}/` : ""}${ActionPaths.AddAppointment}`,
			search: location.search,
		}),
		editButton(!currentClinician),
	];

	if (!isProduction) toolbarButtons.push(printButton(!currentClinician, finalUrl));

	if (error)
		return (
			<>
				<Toolbar toolbarButtons={[]} />
				<ErrorDisplay title={`${error?.title ?? "Unexpected Application Error"}`}>
					{(error?.detail || error?.message) ?? "Unknown error. Please contact an administrator."}
				</ErrorDisplay>
			</>
		);

	return (
		<>
			<Toolbar toolbarButtons={toolbarButtons} />
			<Outlet />
		</>
	);
};
