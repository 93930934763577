import classes from "./NextAppointment.module.scss";
import { PatientCardPatientView } from "../../PatientCard/PatientCard";
import { useNextAppointmentWidget } from "../../../api/hooks/widgets/useNextAppointmentWidget";

export const NextAppointment = ({ patientId }) => {
	const nextAppointment = useNextAppointmentWidget(patientId);

	return (
		<PatientCardPatientView ghost={nextAppointment.isLoading} appointment={{ ...nextAppointment.data, type: "OP" }} />
	);
};
