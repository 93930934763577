import { useState } from "react";
import { CompucareFrame, ScreenSize, useScreenSizeClass } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { faUserDoctor } from "@shfortawesome/pro-light-svg-icons";
import { faUserDoctor as farUserDoctor } from "@shfortawesome/pro-regular-svg-icons";
import { faUserDoctor as fasUserDoctor } from "@shfortawesome/pro-solid-svg-icons";
import { faUserDoctor as fadUserDoctor } from "@shfortawesome/pro-duotone-svg-icons";
import { useLocation } from "react-router";
import { isProduction } from "../../globals/constants";
import { breadcrumbs } from "./breadcrumbs";
import { pages } from "./pages";

const Frame = ({ children }) => {
	const compucareURL =
		window.location.host === "clinician.streets-heaver.com"
			? "https://compucare.streets-heaver.com/home"
			: "https://compucare-dev.streets-heaver.com/home";

	const { currentClinician, clinicians, userSecurityFlags } = useCurrentClinicians();
	const [mobileVisible, setMobileVisible] = useState(false);

	const location = useLocation();
	const pathname = location.pathname;
	const [_, datasource, ...pagepath] = pathname.split("/");

	const width = useScreenSizeClass();

	return (
		<CompucareFrame
			hasActionsBar={width > ScreenSize.TabletPortrait}
			breadcrumbs={breadcrumbs(pathname, datasource)}
			compucareTo={compucareURL}
			mobilePanel={{
				visible: mobileVisible,
				setVisible: (e) => setMobileVisible(e),
			}}
			menuItems={pages(pagepath[0], datasource, userSecurityFlags, setMobileVisible)}
			accounts={currentClinician && clinicians?.length > 0 ? clinicians : undefined}
			accountsMenuInfo={{
				title: "Clinician",
				icon: {
					restIcon: faUserDoctor,
					hoverIcon: farUserDoctor,
					pressedIcon: fasUserDoctor,
					selectedIcon: fadUserDoctor,
				},
			}}
			enabledDefaultMenuItems={{ apps: !isProduction, patients: false, people: false }}
		>
			{children}
		</CompucareFrame>
	);
};

export default Frame;
