import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import dayjs from "dayjs";
import { urlDateTimeFormat } from "../../utils";
import { widgetRefetchInterval } from "../../globals/constants";

export const useUpcomingAppointments = (date, take, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const {
		isLoading: loadingAppointment,
		error: errorAppointment,
		data: dataAppointment,
		hasNextPage: hasMoreAppointments,
		isFetchingNextPage: isFetchingMoreAppointments,
		fetchNextPage: fetchMoreAppointments,
	} = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["upcomingAppointments", currentClinician, date, take],
		queryFn: async ({ pageParam }) => {
			const data = await getUpcomingAppointments(
				currentDataSource?.DataSourceId,
				currentClinician,
				date,
				pageParam,
				take,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchInterval: widgetRefetchInterval,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !isNaN(take) && enabled,
	});

	return {
		loadingAppointment,
		errorAppointment,
		dataAppointment,
		hasMoreAppointments,
		isFetchingMoreAppointments,
		fetchMoreAppointments,
	};
};

const getUpcomingAppointments = async (dataSourceId, clinicianId, date, skip = 0, take) => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/bookings/upcoming?date=${urlDateTimeFormat(date)}&skip=${skip}&take=${take + 1}`,
		{},
		"v2",
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
