import { useState } from "react";
import { BookingLookup, PatientLookup } from "@streetsheaver/compucore";
import { useNavigate, useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { DiscardChangesDialog, FormWrapper, NoAccessPage } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { useBookingsLookup, usePatientsLookup } from "../../api/hooks";
import { EditAppointmentForm } from "../../components/FormComponents/AppointmentForms/EditAppointmentForm";
import { addAppointmentNewDefaultValues } from "../AddAppointment/AddAppointmentSchema";
import { editAppointment } from "./EditAppointmentSchema";
import toast from "react-hot-toast";

export const EditAppointment = () => {
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const { userSecurityFlags } = useCurrentClinicians();
	const patientsQuery = usePatientsLookup();
	const bookingsQuery = useBookingsLookup(selectedPatient?.patientId);

	const navigate = useNavigate();
	const { bookingId } = useParams();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: { ...addAppointmentNewDefaultValues(), ...selectedPatient, ...selectedBooking },
		resolver: zodResolver(editAppointment),
	});

	const [isConfirmDiscardDialogVisible, setConfirmDiscardDialogVisible] = useState(false);

	const onSubmit = (data) => {
		toast.promise(
			new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			}),
			{
				loading: "Editing appointment",
				error: "Error editing appointment",
				success: "Edited appointment",
			},
		);
		console.log("edited appointment data", data);
		navigate(-1);
	};

	const onClose = () => navigate(-1);

	if (!userSecurityFlags.includes("appointment-editappointment")) {
		return <NoAccessPage />;
	}

	if (bookingId) {
		return (
			<>
				<FormProvider {...methods}>
					<FormWrapper header={"Edit an appointment"} fixedHeight>
						<FormWrapper.Body>
							<EditAppointmentForm />
						</FormWrapper.Body>
						<FormWrapper.ButtonBar
							buttons={{
								primaryButton: { onClick: methods.handleSubmit(onSubmit), children: "Confirm changes" },
								secondaryButton: { onClick: () => navigate(-1), children: "Discard" },
								tertiaryButton: {
									navigation: { to: `../${ActionPaths.CancelAppointmentPreselected(bookingId)}` },
									children: "Cancel appointment",
								},
							}}
						/>
					</FormWrapper>
				</FormProvider>
				<DiscardChangesDialog
					onClose={onClose}
					visible={isConfirmDiscardDialogVisible}
					setVisible={setConfirmDiscardDialogVisible}
				/>
			</>
		);
	} else {
		return (
			<FormWrapper header={"Edit an appointment"}>
				<FormWrapper.Body>
					<PatientLookup
						field={{ label: "Patient", isMandatory: true }}
						selected={selectedPatient}
						patientLinkPrefix={"../patient/"}
						setSelected={setSelectedPatient}
						dropdownMaxHeight={"calc(80vh - 190px)"}
						searchQueryData={patientsQuery}
					/>
					<BookingLookup
						field={{ label: "Booking" }}
						selected={selectedBooking}
						setSelected={setSelectedBooking}
						dropdownMaxHeight={"calc(80vh - 190px)"}
						disabled={!selectedPatient}
						searchQueryData={bookingsQuery}
					/>
				</FormWrapper.Body>
				<FormWrapper.ButtonBar
					buttons={{
						primaryButton: {
							navigation: {
								to: selectedBooking
									? `${selectedBooking?.uniqueId}`
									: selectedPatient
										? `../${ActionPaths.EditPatientPreselected(selectedPatient?.patientId)}`
										: undefined,
							},
							children: selectedBooking ? "Edit appointment" : "Edit patient",
							disabled: !selectedPatient,
						},
						secondaryButton: { onClick: () => navigate(-1), children: "Cancel" },
						tertiaryButton: {
							navigation: { to: `../${ActionPaths.CancelAppointmentPreselected(selectedBooking?.uniqueId)}` },
							children: "Cancel appointment",
							disabled: !selectedBooking,
						},
					}}
				/>
			</FormWrapper>
		);
	}
};
