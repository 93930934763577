import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardListCheck } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./UnseenReports.module.scss";
import { useUnseenReportsWidget } from "../../../api/hooks";
import { useParams } from "react-router";
import { isProduction } from "../../../globals/constants";
import { ComingSoon } from "../ComingSoon/ComingSoon";

export const UnseenReports = () => {
	const { patientId } = useParams();
	const unseenReportsQuery = useUnseenReportsWidget(patientId);

	if (isProduction) {
		return <ComingSoon title="Unseen Reports" icon={faClipboardListCheck} />;
	}

	return (
		<div className={classes.unseenReports} data-testid="unreadReports">
			<div className={classes.header}>
				<p className={classes.title}>Unseen reports</p>
				<FontAwesomeIcon icon={faClipboardListCheck} className={classes.icon} />
			</div>
			<p className={classes.number}>{unseenReportsQuery?.data?.unseen ?? "-"}</p>
		</div>
	);
};
