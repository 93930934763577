import { z } from "zod";
import { durationSchema, isFaceToFaceSchema, contactTypeSchema } from "../NewBooking/newBookingSchema";

const arrivedSchema = z.string().regex(/[0-9]{2}:[0-9]{2}/, { message: "Invalid booking time" });

export const editBookingSchema = {
	arrived: arrivedSchema,
	duration: durationSchema,
	isFaceToFace: isFaceToFaceSchema,
	contactType: contactTypeSchema,
};
