import classes from "./AppointmentCalendar.module.scss";
import dayjs from "dayjs";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Badge } from "@streets-heaver/shui2";
import { ScreenSize, useContentSizeClass, usePreviewPanel, useSessionStorage } from "@streetsheaver/compucore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@shfortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router";
import { DateToolbar } from "../DateToolbar/DateToolbar";
import { EventItem } from "../EventItem/EventItem";
import { SchedulerSettings } from "../SchedulerSettings/SchedulerSettings";
import { TodayButton } from "../TodayButton/TodayButton";
import { useSchedulerAvailability } from "../../api/hooks/useSchedulerAvailability";
import { useSchedulerEvents } from "../../api/hooks/useSchedulerEvents";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { NoAccessMessage } from "../../components";
import { getAppointmentType, localFromUTC } from "../../utils";

export const AppointmentCalendar = ({
	setSelectedBooking,
	timescale,
	setTimescale,
	startTime,
	setStartTime,
	dayRange,
	setDayRange,
	hasPermission = true,
	isSchedulerPage,
}) => {
	const [selectedDate, setSelectedDate] = useSessionStorage("appointmentCalendar.selectedDate", new Date());
	const [currentTime, setCurrentTime] = useState(new Date());
	const width = useContentSizeClass();
	const startTimeRef = useRef(null);
	const bodyRef = useRef(null);
	const { setIsSheetMaximised, setIsVisible: setPreviewPanelVisible } = usePreviewPanel();
	const navigate = useNavigate();

	const hourInPixels = 40 * timescale;
	const minuteInPixels = hourInPixels / 60;
	const minutesPerBox = 60 / timescale;

	const selectedDateObject = new Date(selectedDate);

	useEffect(() => {
		if (startTimeRef?.current && bodyRef?.current) {
			bodyRef?.current?.scroll(0, startTimeRef?.current?.offsetTop);
		}
	}, [startTimeRef, bodyRef, startTime]);

	useEffect(() => {
		const updateCurrentTime = setInterval(() => {
			setCurrentTime(new Date());
		}, 60000);
		return () => clearInterval(updateCurrentTime);
	}, []);

	useLayoutEffect(() => {
		if (width < ScreenSize.TabletLandscape && dayRange !== 1) {
			setDayRange(1);
		}
	}, [width, dayRange, setDayRange]);

	const firstDate = new Date(selectedDateObject.getTime());
	if (dayRange !== 1) firstDate.setDate(selectedDateObject.getDate() - (selectedDateObject.getDay() || 7) + 1);

	const dates = [];

	for (let day = 0; day < dayRange; day++) {
		const nextDate = new Date(firstDate.getTime());
		nextDate.setDate(nextDate.getDate() + day);
		dates.push(dayjs(nextDate).startOf("day"));
	}

	const { data: dataAvailability } = useSchedulerAvailability(
		dates[0],
		dates[dayRange - 1].endOf("day"),
		hasPermission,
	);

	const { data: dataEvents } = useSchedulerEvents(dates, hasPermission);

	const splitOverlappingDates = (currentDate, events) => {
		const formattedEvents = [];
		const groupedEvents = [];
		const currentStart = localFromUTC(currentDate).startOf("day");
		const currentEnd = localFromUTC(currentDate).endOf("day");

		events.map((event) => {
			const localStart = localFromUTC(event?.start);
			const localEnd = localFromUTC(event?.end);
			const newRenderStart = localStart?.isBefore(currentStart) ? currentStart : localStart;
			const newRenderEnd = localEnd?.isAfter(currentEnd) ? currentEnd : localEnd;
			formattedEvents.push({
				...event,
				start: localStart,
				end: localEnd,
				renderStart: newRenderStart,
				renderEnd: newRenderEnd,
				borderRadius:
					localStart.isSame(newRenderStart) && localEnd.isSame(newRenderEnd)
						? "2px 8px 8px 2px"
						: !localStart.isSame(newRenderStart)
							? "2px 0 8px 2px"
							: "2px 8px 0 2px",
			});
		});

		formattedEvents.map((event, i) => {
			if (i < 1) {
				groupedEvents.push([event]);
			} else {
				const arrayOfPreviousEnds = groupedEvents[groupedEvents.length - 1].map((item) => item.renderEnd);
				const previousEnd = dayjs.max(arrayOfPreviousEnds);
				if (event.renderStart.isBefore(previousEnd)) {
					groupedEvents[groupedEvents.length - 1].push(event);
				} else {
					groupedEvents.push([event]);
				}
			}
		});

		return { date: currentDate, events: groupedEvents };
	};

	const calculatePosition = (start, end) => {
		const newStart = dayjs(start);
		const newEnd = dayjs(end);
		const top = hourInPixels * newStart.hour() + minuteInPixels * newStart.minute();
		const differenceInMinutes = newEnd.diff(newStart, "minutes");
		const height = minuteInPixels * differenceInMinutes;
		return { height: height, top: top, width: "100%" };
	};

	if (!hasPermission) {
		return <NoAccessMessage />;
	}

	const processedDataEvents = dataEvents ? dataEvents?.map((day) => splitOverlappingDates(day?.date, day?.events)) : [];

	return (
		<div className={classes.AppointmentCalendar} data-testid="appointment-calendar">
			<div className={classes.Toolbar} data-testid="appointmentToolbar">
				{width >= ScreenSize.TabletPortrait && width < ScreenSize.Small && (
					<TodayButton setCurrentDate={setSelectedDate} />
				)}
				<DateToolbar
					todayButton={width >= ScreenSize.Small}
					currentDate={selectedDateObject}
					setCurrentDate={setSelectedDate}
					step={dayRange === 1 ? 86400000 : 604800000}
				/>
				{width >= ScreenSize.TabletPortrait && (
					<SchedulerSettings
						timescale={timescale}
						setTimescale={setTimescale}
						startTime={startTime}
						setStartTime={setStartTime}
						dayRange={dayRange}
						setDayRange={setDayRange}
					/>
				)}
			</div>
			{width >= ScreenSize.TabletPortrait && (
				<div className={classes.Header} style={{ gridTemplateColumns: `40px repeat(${dayRange}, 1fr)` }}>
					<div className={clsx(classes.Cell, classes.BadgeCell)} style={{ borderWidth: "1px 0.5px 1px 1px" }}>
						{dataAvailability?.surgical24h && (
							<Badge rounded text="24h" type="tint" color="danger" automationId={"24h-surgical"} />
						)}
						{dataAvailability?.outpatient24h && (
							<Badge rounded text="24h" type="tint" automationId={"24h-outpatient"} />
						)}
					</div>
					{dates.map((date, i) => (
						<div
							data-testid="appointment-calendar-day"
							className={clsx(classes.Cell, date.isSame(selectedDateObject, "day") && classes.Selected)}
							key={`date-${date.get("date")}`}
							style={{
								borderWidth: i === dayRange - 1 ? "1px 1px 1px 0.5px" : "1px 0.5px 1px 0.5px",
							}}
						>
							<p className={classes.Date}>{date.get("date")}</p>
							{date.format("dddd")}
						</div>
					))}
				</div>
			)}
			<div
				className={classes.Body}
				style={{ gridTemplateColumns: `40px repeat(${dayRange}, 1fr)` }}
				ref={bodyRef}
				data-testid="appointment-calendar-body"
			>
				<div className={classes.HiddenCell} key={`time-cell-0`} />
				{[...Array(dayRange)].map((val, cell) => (
					<div className={classes.HiddenEventCell} style={{ height: 0, width: "100%" }} key={`${cell}-hidden-event`}>
						{dates[cell % dayRange]?.isSame(currentTime, "day") && (
							<div
								className={classes.TimeIndicator}
								style={{
									top: `${hourInPixels * currentTime.getHours() + minuteInPixels * currentTime.getMinutes()}px`,
								}}
							>
								<div className={classes.IndicatorCircle} />
								<div className={classes.IndicatorLine} />
								<div className={classes.IndicatorCircle} />
							</div>
						)}
						{processedDataEvents
							?.find((event) => localFromUTC(event?.date).isSame(dates[cell % dayRange]))
							?.events?.map((array) => {
								return array?.map((session, index) => {
									const positionOfSession = calculatePosition(session?.renderStart, session?.renderEnd);
									const heightOfSession = positionOfSession.height;
									return (
										<div
											key={`event-session-${index}`}
											className={classes.EventItem}
											style={{
												top: positionOfSession.top + 2,
												borderRadius: session?.borderRadius,
												width: `calc((100% / ${array.length}) - 8px)`,
												left: `calc((${index}) * ((100% / ${array.length})) + 4px)`,
											}}
										>
											<EventItem
												key={`event-session-${index}`}
												automationId={`event-item-${session?.type}`}
												compact
												appointment={session}
												height={heightOfSession < 4 ? heightOfSession : heightOfSession - 4}
												onClick={() => {
													setSelectedBooking({
														id: session.bookingUniqueId,
														type: getAppointmentType(session.type),
													});
													setPreviewPanelVisible(true);
													setIsSheetMaximised(true);
												}}
												setSelectedBooking={setSelectedBooking}
											/>
										</div>
									);
								});
							})}
					</div>
				))}
				<div className={classes.HiddenCell} key={`time-cell-1`} />
				{[...Array(dayRange)].map((_, cell) => (
					<div className={classes.HiddenBackgroundCell} style={{ height: 0, width: "100%" }} key={`${cell}-hidden-bg`}>
						<div className={classes.CellBackground} style={{ height: hourInPixels * 24 }} />
					</div>
				))}

				<div className={classes.HiddenCell} key={`time-cell-2`} />
				{[...Array(dayRange)].map((_, cell) => (
					<div className={classes.HiddenAvailabilityCell} key={`${cell}-hidden-availability`}>
						{cell < dayRange &&
							!!dataAvailability?.groupedAvailability &&
							dataAvailability?.groupedAvailability?.map((array, i) => {
								if (array?.date === dayjs(dates[cell]).format("DD-M-YYYY")) {
									return array?.data?.map((session, index) => {
										const sessionStart = dayjs(session?.start);
										const sessionEnd = dayjs(session?.end);
										const scale = calculatePosition(session?.start, session?.end);
										const boxHalfwayPoint = Math.ceil(minutesPerBox / 2);
										const isStartingBoxOverHalfway =
											sessionStart.minute() % minutesPerBox !== 0 &&
											Math.ceil(sessionStart.minute()) >= boxHalfwayPoint;
										const isEndingBoxNotFull = sessionEnd.minute() % minutesPerBox !== 0;
										const initialAdjustment = isStartingBoxOverHalfway
											? 40 - (scale?.top % 40) < 40
												? 40 + (40 - (scale?.top % 40))
												: 40
											: 40 - Math.floor(scale?.top % 40);
										const defaultSessionHourCount =
											sessionEnd.diff(sessionStart, "minutes") >= boxHalfwayPoint
												? Math.floor(sessionEnd.diff(sessionStart, "minutes") / minutesPerBox)
												: 0;
										let sessionHourCount = defaultSessionHourCount;
										if (
											isStartingBoxOverHalfway &&
											isEndingBoxNotFull &&
											sessionEnd.diff(sessionStart, "minutes") % minutesPerBox === 0
										)
											sessionHourCount -= 1;
										return (
											<div
												className={classes.AvailabilitySession}
												key={`availability-session-${i}-${index}`}
												style={{
													...scale,
													backgroundColor:
														session?.type === "OP" ? "var(--backgroundBrandTwo)" : "var(--secondaryAppColour)",
												}}
											>
												{[...Array(sessionHourCount)].map((val, siteIndex) => (
													<span
														className={classes.SiteName}
														key={`availability-${index}-${siteIndex}`}
														style={{ height: siteIndex === 0 ? initialAdjustment : "40px" }}
													>
														<FontAwesomeIcon className={classes.SiteIcon} icon={faLocationDot} />
														{session?.site}
													</span>
												))}
											</div>
										);
									});
								}
							})}
					</div>
				))}
				{[...Array(24)].map((_, time) => (
					<React.Fragment key={`time-${time}`}>
						<div
							className={clsx(classes.Cell, classes.Time)}
							ref={time === startTime ? startTimeRef : undefined}
							style={{
								gridRow: `span ${timescale}`,
								borderWidth: time === 23 ? "0.5px 0.5px 1px 1px" : "0.5px 0.5px 0.5px 1px",
							}}
							data-testid={`timeCell-${time}`}
						>
							<p>{time}:00</p>
						</div>
						{[...Array(dayRange * timescale)].map((_, cell) => {
							const cellHour = time.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
							const cellMinute = (minutesPerBox * Math.floor(cell / dayRange)).toLocaleString("en-US", {
								minimumIntegerDigits: 2,
								useGrouping: false,
							});
							const cellTime = `${cellHour}:${cellMinute}`;
							return (
								<div
									className={classes.Cell}
									key={`cell-${time}-${cell}`}
									style={{ borderWidth: handleCellBorderWidth(time, cell, dayRange, timescale) }}
									onClick={() => {
										navigate(`${isSchedulerPage ? "../" : ""}${ActionPaths.AddFullAppointment}`, {
											state: {
												date: dates[cell % dayRange].toDate(),
												time: cellTime,
												duration: minutesPerBox < 31 && { value: minutesPerBox, label: `${minutesPerBox} mins` },
											},
										});
									}}
								/>
							);
						})}
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

const handleCellBorderWidth = (time, cell, dayRange, timescale) => {
	if (cell === dayRange - 1 || cell === dayRange * timescale - 1) {
		if (time === 23 && cell === dayRange * timescale - 1) {
			return "0.5px 1px 1px 0.5px";
		} else {
			return "0.5px 1px 0.5px 0.5px";
		}
	} else if (time === 23 && cell >= dayRange) {
		return "0.5px 0.5px 1px 0.5px";
	}
};
