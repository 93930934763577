import { useReportTemplatesLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const ReportTemplatesLookup = ({ label = "Template", name = "template", isMandatory, isSurgical }) => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useReportTemplatesLookup(isSurgical, searchTerm);
	return (
		<SearchableLookup
			control={form.control}
			label={label}
			lookupName={name}
			setSearchTerm={setSearchTerm}
			query={query}
			isMandatory={isMandatory}
		/>
	);
};
