import { useCancellationReasonsLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const CancellationReasonsLookup = ({
	label = "Cancellation reason",
	name = "cancellationReason",
	isMandatory,
}) => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useCancellationReasonsLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={label}
			lookupName={name}
			setSearchTerm={setSearchTerm}
			query={query}
			isMandatory={isMandatory}
		/>
	);
};
