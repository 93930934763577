import { Datebox, Dropdown, Lookup } from "@streets-heaver/shui2";
import { Switch, Timebox } from "@streets-heaver/shui2/inputs";
import { Controller, useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useContactTypesLookup } from "../../../../../api/hooks";
import { durationOptions } from "./durationOptions";

export const NewBooking = () => {
	const form = useFormContext();

	const dateName = "date";
	const timeName = "time";
	const durationName = "duration";
	const isFaceToFaceName = "isFaceToFace";
	const contactTypeName = "contactType";

	const contactTypeQuery = useContactTypesLookup();

	return (
		<FormGroup>
			<FormField label="Date" name={dateName} isMandatory>
				<Controller
					name={dateName}
					control={form.control}
					render={({ field: { value, onChange } }) => (
						<Datebox
							customWidth={160}
							size="large"
							type={"filledDarker"}
							date={value}
							showInitialDate={typeof value !== "undefined"}
							onSelected={(e) => onChange(e)}
						/>
					)}
				/>
			</FormField>
			<FormField label="Time" name={timeName} isMandatory>
				<Controller
					name={timeName}
					control={form.control}
					render={({ field: { onChange, value, ref } }) => (
						<Timebox
							type="filledDarker"
							size="large"
							dropdownStep={5}
							inputStep={5}
							value={value}
							onChange={onChange}
							ref={ref}
						/>
					)}
				/>
			</FormField>
			<div style={{ width: 200 }}>
				<FormField label="Duration" name={durationName} isMandatory>
					<Controller
						name={durationName}
						render={({ field: { value, onChange } }) => (
							<Dropdown
								placeholder={{ label: "Select a duration" }}
								value={value}
								onChange={onChange}
								type={"filledDarker"}
								size="large"
								options={durationOptions}
							/>
						)}
					/>
				</FormField>
			</div>
			<FormField label="Is face to face?" isMandatory name={isFaceToFaceName}>
				<Controller
					control={form.control}
					name={isFaceToFaceName}
					render={({ field: { onChange, value } }) => (
						<Switch checked={value} onChange={onChange} label={value ? "Yes" : "No"} />
					)}
				/>
			</FormField>
			{!form.watch(isFaceToFaceName) && (
				<div style={{ width: 340 }}>
					<FormField label="Contact type" name={contactTypeName} isMandatory>
						<Controller
							name={contactTypeName}
							control={form.control}
							render={({ field: { value, onChange } }) => (
								<Lookup
									dropdownMaxHeight={"calc(80vh - 190px)"}
									selected={value}
									setSelected={onChange}
									searchQueryData={contactTypeQuery}
								/>
							)}
						/>
					</FormField>
				</div>
			)}
		</FormGroup>
	);
};
