import { Divider } from "@streets-heaver/shui2";
import classes from "./OrderStatus.module.scss";
import { useMeasure } from "@uidotdev/usehooks";
import { useParams } from "react-router";
import { useOrderStatusWidget } from "../../../api/hooks";
import { isProduction } from "../../../globals/constants";
import { ComingSoon } from "../ComingSoon/ComingSoon";
import { faClipboardListCheck } from "@shfortawesome/pro-duotone-svg-icons";

export const OrderStatus = () => {
	const { patientId } = useParams();
	const orderStatusQuery = useOrderStatusWidget(patientId);
	const [cardRef, { width: containerWidth }] = useMeasure();
	const limitStatuses = containerWidth < 282;
	const allStatuses = [
		{
			number: orderStatusQuery?.data?.awaitingReport,
			title: "Awaiting report",
			colour: "var(--orderAwaitingReport)",
		},
		{ number: orderStatusQuery?.data?.reportEntered, title: "Report entered", colour: "var(--orderRequested)" },
		{ number: orderStatusQuery?.data?.reportVerified, title: "Verified", colour: "var(--orderReportVerified)" },
		{ number: orderStatusQuery?.data?.unseen, title: "Unseen", colour: "var(--orderSent)" },
	];
	const statusesToLoop = limitStatuses ? [allStatuses.shift(), allStatuses.pop()] : allStatuses;

	if (isProduction) {
		return <ComingSoon title="Order status" icon={faClipboardListCheck} />;
	}

	return (
		<div className={classes.orderStatus} ref={cardRef}>
			<div className={classes.header}>
				<p className={classes.title}>Order status</p>
				<Divider />
			</div>
			{statusesToLoop?.length > 0 &&
				statusesToLoop?.map((status, i) => {
					return (
						<div
							className={classes.statusBox}
							key={`widget-orderstatus-status-${i}`}
							style={{ padding: limitStatuses ? "0px 8px" : "8px" }}
						>
							<div className={classes.statusLeft}>
								<div
									className={classes.statusLine}
									style={{ backgroundColor: status?.colour ?? "var(--foregroundOne)" }}
								/>
								<p className={classes.statusTitle}>{status?.title ?? "-"}</p>
							</div>
							<p className={classes.statusNumber}>{status?.number ?? "0"}</p>
						</div>
					);
				})}
		</div>
	);
};
