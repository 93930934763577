import { Button, Ghost, SegmentedControl, TabList } from "@streets-heaver/shui2";
import classes from "./MyPatients.module.scss";
import { useRef, useState } from "react";
import { faFilter as falFilter } from "@shfortawesome/pro-light-svg-icons";
import { faFilter as fasFilter } from "@shfortawesome/pro-solid-svg-icons";
import { faFilter as farFilter } from "@shfortawesome/pro-regular-svg-icons";
import { faFilter as fadFilter } from "@shfortawesome/pro-duotone-svg-icons";
import { ScreenSize, useSessionStorage, PreviewPanel, useContentSizeClass, Toolbar } from "@streetsheaver/compucore";
import { FilterButtons, MyPatientsTable, LoadingSpinner, NoAccessPage, PatientCardPanel } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { useMyPatientBookings, useMyPatientCounts } from "../../api/hooks/mypatients";
import { getColumns, shortDateFormat, bookingStatus } from "../../utils";
import clsx from "clsx";
import { newButton, editButton, printButton } from "../../layouts/Layout/toolbarButtons";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { Outlet } from "react-router-dom";
import { Filter } from "../../components/FiltersDialog/Filter";
import { validateFilters } from "./validateFilters";
import { MyPatientsFilterDialog } from "../../components/FiltersDialog/MyPatientsFilterDialog.jsx/MyPatientsFilterDialog";
import { useFilterList } from "../../utils/hooks/useFilterList";
import { FiltersList } from "../../components/FiltersList/FiltersList";
import { useSitesLookupFromIds } from "../../api/hooks/lookups/useSitesLookup";
import { isProduction } from "../../globals/constants";

const MyPatients = () => {
	const { currentClinician, userSecurityFlags } = useCurrentClinicians();
	const [selectedBooking, setSelectedBooking] = useState(null);
	const toolbarButtons = [
		newButton(!currentClinician, ActionPaths.AddPatient, "New Patient"),
		editButton(!currentClinician),
	];

	if (!isProduction) toolbarButtons.push(printButton(!currentClinician, "mypatients"));

	const initialFilters = {
		start: new Filter([], {
			toStringFunction: (values) => `From = ${shortDateFormat(values[0])}`,
		}),
		end: new Filter([], {
			toStringFunction: (values) => `To = ${shortDateFormat(values[0])}`,
		}),
		site: new Filter([], {
			toStringFunction: (values) => <SiteRender sites={values} />,
		}),
		status: new Filter([], {
			toStringFunction: (values) => `Status = ${values.join(", ")}`,
			getEndpointValueFunction: (values) => values.map((value) => bookingStatus[value]).flat(),
		}),
	};

	const { appliedFilters, updateAppliedFilters } = useFilterList(initialFilters, validateFilters);

	const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
	const [selectedTable, setSelectedTable] = useSessionStorage("mypatients.selectedTable", "outpatient");
	const width = useContentSizeClass();
	const tableRef = useRef(null);

	const outpatients = useMyPatientBookings(
		"outpatient",
		appliedFilters,
		Math.ceil(tableRef?.current?.offsetHeight / 48),
		userSecurityFlags?.includes(`mypatients-outpatient`),
	);
	const inpatients = useMyPatientBookings(
		"inpatient",
		appliedFilters,
		Math.ceil(tableRef?.current?.offsetHeight / 48),
		userSecurityFlags?.includes(`mypatients-inpatient`),
	);
	const surgical = useMyPatientBookings(
		"surgical",
		appliedFilters,
		Math.ceil(tableRef?.current?.offsetHeight / 48),
		userSecurityFlags?.includes(`mypatients-surgical`),
	);

	const counts = useMyPatientCounts(appliedFilters, userSecurityFlags?.includes("mypatients"));

	const tabs = [
		{ label: "Outpatient", value: "outpatient" },
		{ label: "Inpatient", value: "inpatient" },
		{ label: "Surgical", value: "surgical" },
	];

	return (
		<div className={classes.myPatients} data-testid={"pageMyPatients"}>
			<PreviewPanel
				onBackButtonClick={
					selectedBooking
						? () => {
								setSelectedBooking(null);
							}
						: undefined
				}
				title={selectedBooking && `${selectedBooking.type} booking`}
			>
				{selectedBooking && (
					<PatientCardPanel
						bookingType={selectedBooking.type}
						bookingUniqueId={selectedBooking.id}
						overrideTab={selectedBooking.overrideTab}
					/>
				)}
			</PreviewPanel>

			<Toolbar toolbarButtons={toolbarButtons} />
			{!currentClinician ? (
				<LoadingSpinner />
			) : (
				<>
					{!userSecurityFlags.includes("mypatients") ? (
						<NoAccessPage />
					) : (
						<>
							<Outlet />
							{width >= ScreenSize.TabletLandscape && (
								<FilterButtons
									selectedFilters={appliedFilters.status.value}
									toggleFilter={(status) => {
										updateAppliedFilters((prev) => {
											const newFilters = { ...prev };
											if (prev.status.value.includes(status)) {
												newFilters.status.value = prev.status.value.filter((item) => item !== status);
											} else {
												newFilters.status.value.push(status);
											}
											return newFilters;
										});
									}}
									counts={counts?.data}
									ghost={counts?.isLoading}
								/>
							)}
							<div className={classes.myPatientsContent}>
								{width >= ScreenSize.TabletPortrait && (
									<div className={classes.filtersBar}>
										<FiltersList
											appliedFilters={appliedFilters}
											shownCondition={(filterName) => !(width >= ScreenSize.TabletLandscape && filterName === "status")}
											updateAppliedFilters={updateAppliedFilters}
										/>
										<Button
											onClick={() => setFiltersPopupOpen(true)}
											size="large"
											type="subtle"
											colour="grey"
											icon={{
												restIcon: falFilter,
												hoverIcon: farFilter,
												pressedIcon: fasFilter,
												selectedIcon: fadFilter,
												iconSecondaryColour: "var(--foregroundBrandOne)",
											}}
											selected={filtersPopupOpen}
										>
											Filters
										</Button>
									</div>
								)}
								{width < ScreenSize.TabletLandscape ? (
									<div className={clsx(width >= ScreenSize.TabletPortrait && classes.centerTabs)}>
										<SegmentedControl
											segments={tabs}
											type="tabs"
											onChange={setSelectedTable}
											defaultSegment={selectedTable || "outpatient"}
											groupName="myPatients-type"
											automationId="mypatients-table-control"
										/>
									</div>
								) : (
									<TabList
										tabs={tabs}
										onChange={setSelectedTable}
										updateSelectedTab={selectedTable}
										automationId="mypatients-table-control"
									/>
								)}
								{(!selectedTable || selectedTable === "outpatient") && (
									<MyPatientsTable
										query={outpatients}
										name={"outpatient"}
										columns={getColumns("Outpatient")}
										setFiltersPopupOpen={setFiltersPopupOpen}
										filtersCount={Object.keys(appliedFilters).length}
										setSelectedBooking={setSelectedBooking}
										tableRef={tableRef}
									/>
								)}
								{selectedTable === "inpatient" && (
									<MyPatientsTable
										query={inpatients}
										name={"inpatient"}
										columns={getColumns("Inpatient")}
										setFiltersPopupOpen={setFiltersPopupOpen}
										filtersCount={Object.keys(appliedFilters).length}
										setSelectedBooking={setSelectedBooking}
										tableRef={tableRef}
									/>
								)}
								{selectedTable === "surgical" && (
									<MyPatientsTable
										query={surgical}
										name={"surgical"}
										columns={getColumns("Surgical")}
										setFiltersPopupOpen={setFiltersPopupOpen}
										filtersCount={Object.keys(appliedFilters).length}
										setSelectedBooking={setSelectedBooking}
										tableRef={tableRef}
									/>
								)}
							</div>
						</>
					)}
				</>
			)}
			<MyPatientsFilterDialog
				visible={filtersPopupOpen}
				setVisible={setFiltersPopupOpen}
				appliedFilters={appliedFilters}
				setAppliedFilters={updateAppliedFilters}
				defaultFilters={initialFilters}
			/>
		</div>
	);
};

const SiteRender = ({ sites }) => {
	const { data: sitesData, isLoading: sitesIsLoading } = useSitesLookupFromIds(sites);

	if (sitesIsLoading) return <Ghost height={16} width={100} />;
	return `Site = ${sitesData?.data?.map((site) => site.name).join(", ")}`;
};

export default MyPatients;
